
import React, { useState, useEffect, useContext } from 'react';
import {Row, Col} from 'react-bootstrap';
import { SocketContext } from '../context/socket';
import Contador from './../component/contador'; 
import Divisor from './../component/divisor';
import Card from './../component/card';
import Slide from './../component/slide';
import Ultlance from './../component/ultlance';
import Darlance from './../component/darlance';
import Doador from './../component/doador';
import Olhar from './../component/olhar';
import {get_lote, darlance, lances} from './../model/lotes';
import { cores } from './../config';
import { useLocation } from 'react-router-dom';


import ReactDOM from 'react-dom';

const Lote = (props) => {

    
  
    

    const socket = useContext(SocketContext);
    const [loteCarregado, setLoteCarregado] = useState(false);
    const [lanceCarregado, setLanceCarregado] = useState(false);
    const [fetch, setLote] = useState(null);
    const [fetchLance, setFetchLance] = useState(null);
    const [spec, setSpec] = useState(0);
    const [min, setMin] = useState(0);
    const [msg, setMsg] = useState(null);
    const [enableScroll, setEnableScroll] = useState(false);
    const [startDate, setStartDate] = useState(0);
    const lanceUpdate = () =>{}

    const darUmLance = async (v) => {
        var valorAjustado;
        if(isNaN(v)){
            valorAjustado = parseFloat( v.replace(',', '.') );
        }else{
            valorAjustado = v;
        }
        if(valorAjustado>=min){      
            await darlance([props.numero, valorAjustado]).then(
                d =>{
                    if(d.success){
                        setMsg(d.msg);
                        setMin(parseFloat(v) + 10);
                        get_lances();
                        socket.emit('lance', {numero:props.numero, valor:valorAjustado});
                    }else{
                        setMsg(d.msg);
                    }
                }
            )         
        }else{
            setMsg('O valor precisa ser maior que o atual!');
        }
    }

    async function get_lances(){
        setLanceCarregado(true);
        await lances(props.numero).then(
            data => {
                setFetchLance(data);
            }
        )
    }

    async function getLote(){
        await get_lote(props.numero).then( 
            data => { 
               
                if(!loteCarregado){
                    setLote(data);
                    setStartDate(data.lote.strfim);
                    setMin( (data.lote.lance_atual[0]) ? parseFloat(data.lote.lance_atual[0].valor) + 10 : parseFloat(data.lote.valor_inicial) );
                }
            } 
        )
            
    }

    useEffect(() => {
        socket.emit('openpage', props.numero);
        socket.on('live', (d)=>{
            if(d.numero==props.numero) setSpec(d.spec)
        });
        socket.on('novolance', (d)=>{
            if(d.numero==props.numero){
                setMin(parseFloat(d.valor) + 10);
                get_lances();
            }
        });
        if(!lanceCarregado) get_lances();
        if(!loteCarregado){
            
            getLote();
            setLoteCarregado(true);
        }
        
        document.title = "ARCA DE NOÉ - LOTE "+props.numero;
        document.getElementsByTagName('description').content = ".";
    }, [fetch]);

   
   
    
    return (fetch) ? 
        <>
        {(fetch.lote.recebelance) ? 
         <Row style={{marginTop:'5vh'}}>
             <Col md={8}>
                 <Slide lote={fetch.lote} itens={fetch.itens}/>
             </Col>
             <Col md={4}>                 
                 <Row>
                     <Col>
                        <div style={{color:cores.secondary}}>
                            <div><small>Valor Inicial:</small></div> 
                            R$ {(fetch.lote.valor_inicial<min) ? 
                            <span style={{textDecoration:'line-through red'}}>{fetch.lote.valor_inicial}</span> : 
                            <span>{fetch.lote.valor_inicial}</span>}
                        </div>
                        <h1 style={{
                                color:cores.primary, 
                                textAlign:'left',
                                fontWeight:600
                            }}>Lote {props.numero}</h1></Col>
                 </Row>
                 <Row>
                     <Col>
                        {(startDate) ? 
                            (fetch.lote.recebelance) ?
                                <Contador startDate={startDate}/> 
                            : '' : ''
                        }
                    </Col>
                 </Row>
                 <Row>
                    <Col sm={12}>{msg}</Col>
                    <Col>
                        <Darlance 
                            lotevendido={fetch.lote.vendido}
                            lanceLiberado={fetch.lote.recebelance} 
                            darUmLance={darUmLance} 
                            valor={min}
                        />
                    </Col>
                    
                 </Row>
                 <Row>
                    
                    <Col>
                        <Olhar bg={cores.primary} spec={spec}/>
                    </Col>
                 </Row>
                 <Row>
                    <Col>
                        { (fetchLance) ? 
                            (fetch.lote.recebelance) ?
                                <Ultlance lances={fetchLance}/> 
                                : ''
                            : 
                            <div>Carregando...</div>
                        }
                    </Col>
                 </Row>
             </Col>             
         </Row>
         :
         <Row style={{marginTop:'5vh'}}>
             <Col md={12}>
             <div style={{
                            width:'100%',
                            backgroundColor:'red', 
                            color:cores.light,
                            padding:'2vh',
                            borderRadius:'0.3vh',
                            fontSize:'1.5vh',
                            fontWeight: '400',
                            marginTop:'2vh',
                            marginBottom: '2vh',
                            textAlign:'justify'
                            }}>
                            
                            
                            Lote não liberado para receber lances!
                            
                            
                                
                        </div>
             </Col>
             <Col md={12}>
                 <Slide lote={fetch.lote} itens={fetch.itens}/>
             </Col>
         </Row>
        }
         <Divisor bg={cores.primary} cor={cores.light} titulo="Itens presente no lote"/>
         <Doador itens={fetch.itens} />
        </>
        : 
        <div>carregando...</div>
 }

export default Lote;