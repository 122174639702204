
    import React from 'react';
    import {Col} from 'react-bootstrap';

    const Parceiro = (props) => {
        
        return (
            <Col xs={6} sm={2}>
                
                <div style={{                     
                        textAlign:'center',
                        marginBottom:'1vh'
                    }}>
                    <img 
                    style={{maxHeight:'7vh'}}
                    src={`https://img.arcadenoeunai.com.br/2021/parceiro/${props.parc.logo}`} 
                    alt={props.parc.nome}/>
                    </div>
            </Col>
        )
    }

    export default Parceiro;

