import React, {useState, useEffect} from 'react';
import './menu.css';

import { Link } from 'react-router-dom';

import {Container, Button, Row, Col} from 'react-bootstrap'

import styled, { keyframes } from 'styled-components';
import { fadeInRight } from 'react-animations';

const fadeOutAnaimation = keyframes`${fadeInRight}`;
const fadeInAnimation = keyframes`${fadeInRight}`;

const FadeOutDiv = styled.div`
    animation: 0.4s ${fadeOutAnaimation};`;
    
const FadeInDiv = styled.div`
    position: absolute;
    top:0;
    right: 0;
    z-index: 999;
    background-color: #fff;
    width:70vw;
    height:100vh;
    animation: 0.4s ${fadeInAnimation};
`;



function Mobile(props){

     return (props.aberto) ?
     <FadeInDiv>
        <Container fluid className="menu-content">
            <Container className="list-menu">
                <Button onClick={ props.closeMenu }>closemenue</Button>
                <Link to="/" onClick={ props.closeMenu}>home</Link>
                <Link to="/1" onClick={ props.closeMenu}>teste</Link>
            </Container>
        </Container>
    </FadeInDiv>
   : 
    <FadeOutDiv>
       <Container fluid className="menu-content">
            <Container className="list-menu">
            <Button onClick={ props.closeMenu }>closemenue</Button>
            </Container>
        </Container>
    </FadeOutDiv>
   

        
   
}

function Web(){
    return (
    <Container fluid className="menu-content">
        <Container>

            <Row>
                <Col>
                <div className="menu">
                    <div><Link to="/">PÁGINA INCIAL</Link></div>
                    <div><Link to="/regra">REGRAS DO LEILÃO</Link></div>
                    
                </div>
                </Col>
            </Row>
            
            
            
            

        </Container>
    </Container> 
    )
}

function Menuopc(){

    const size = useWindowSize();
    const [aberto, setAberto] = useState(false);

    const closeMenu = () => setAberto(!aberto)
    //return (size.width>900) ? Web() : <Mobile closeMenu={closeMenu} aberto={aberto}/>;  
    return Web();  
}



export default Menuopc;




function useWindowSize() {

    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
  
    useEffect(() => {
      
      function handleResize() {        
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      } 
      
      window.addEventListener("resize", handleResize);
  
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }, []);
  
    return windowSize;
  }