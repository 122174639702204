import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';

import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';

import FavoriteIcon from '@material-ui/icons/Favorite';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import {Link} from 'react-router-dom';

import ContadorHome from './../contadorhome'; 
import { CardHeader } from '@material-ui/core';
import { cores } from '../../config';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 500,
      marginTop: '2vh'
    },
    media: {
      height: 0,
      paddingTop:'150%',
      objectFit: 'contain',
      borderBottomWidth: '1px',
      borderBottomColor: '#dcdcdc',
      borderBottomStyle:'solid'
    },
    content: {
      margin:0,
      padding:0
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
    header:{
      paddingTop:'1vh',
      paddingBottom: 0,
      paddingLeft: '1vh',
      paddingRight: 0,
      color: cores.primary
    },
    itemTitulo:{
      fontSize:'1.2vh',
      paddingRight: '3px'
    },
    itemBody:{
      flex:1,
    },
    itemArea: {
      padding:'3px',
      height:'10vh',
      overflowY:'scroll'
    },
    itens:{
      fontSize:'1.7vh',
      paddingBottom:'1px',
    },
    lances:{
      fontSize:'1.5vh',
      width: '100%',
      paddingTop:'0.5vh',
      marginBottom:'0.5vh',
      borderBottomColor: '#ccc',
      borderBottomStyle: 'dotted',
      borderBottomWidth: '1px'
    },
    lances2:{
      fontSize:'1.5vh',
      width: '100%',
      paddingTop:'0.5vh',
      marginBottom:'0.5vh'
    },
    contaItens:{
      position: 'absolute',
      right:'10%',
      paddingTop:'3%',
      color:'purple'
    },
    contaItensNumero:{
      textAlign:'center',
      fontWeight:'bold'
    },
    contaItensTexto:{
      
    }
  }),
);

function priceToView(v){
  var valor = parseFloat(v).toFixed(2);
  return 'R$ '+valor.replace('.', ',');
}

export default function RecipeReviewCard(props) {

  

  const classes = useStyles();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  
  
  return (
   
    <Card className={classes.root}>
      <Link to={`/${props.lote.numero}`}>
      
      

      <CardMedia
        className={classes.media}
        image={`https://img.arcadenoeunai.com.br/2022/lotes/${props.lote.numero}.png?${Math.floor(Math.random() * 100)}`}
        title={`Lote ${props.lote.numero}`}
      />
      <div className={classes.contaItens}>
        <div className={classes.contaItensNumero}><CardGiftcardIcon/> {props.itens.length}</div>
        <div className={classes.contaItensTexto}>{(props.itens.length>1) ? 'Itens' : 'Item'}</div>
      </div>
      </Link>

      <CardHeader 
        className={classes.header} 
        title={`Lote ${props.lote.numero}`}
        subheader={
          (props.lote.recebelance) ? 
            (props.lote.lance_atual[0]) ? priceToView(props.lote.lance_atual[0].valor) : priceToView(props.lote.valor_inicial)
            :
            ''
          }
        />
        {(props.lote.recebelance) ? 
          <CardContent className={classes.content}>
            <ContadorHome startDate={props.lote.strfim}/>
        </CardContent>
      : ''}
      <CardActions disableSpacing>
        <div className={classes.itemBody}>
          <div className={classes.itemTitulo}>
            Itens:
          </div>
          <div className={classes.itemArea}>
                { props.itens.map( (item, x) =>{
                  return <div className={classes.itens} key={x}>
                          {parseInt(item.qt)} {item.abreviacao.toUpperCase()} {item.produto}
                        </div> 
                  })
                }   
          </div> 
        </div>
      </CardActions>
      <CardActions disableSpacing>
      <div className={classes.itemBody} style={{paddingBottom:'2vh', paddingTop:'1vh', borderTopColor:'#ccc', borderTopWidth:'1px', borderTopStyle:'dotted'}}>
            
          <div className={classes.itemTitulo}>
            Últimos Lances:
          </div>
          { 
          (props.lote.lance_atual.length>0) ? 
          <div className={classes.itemArea}>
                { props.lote.lance_atual.map( (lance, x) =>{
                  return <div 
                          className={classes.lances} 
                          style={(x==0) ? {fontWeight:'bold', color:'#333'} : {color:'#444'} }key={x}>
                            R$ {lance.valor.replace('.', ',')} - {lance.nome.toUpperCase()} 
                         </div> 
                  })
                }   
          </div> 
          :
                <div className={classes.lances2} > Sem lances até o momento</div>
          }
        </div>
      </CardActions>
        
      
    </Card>
    
  );
}
