import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
function Barra(d){

    return (
            <Container fluid>
                <Row>
                    <Col className="barra" style={{
                        textAlign:'center',
                        backgroundColor: d.bg,
                        color: d.cor,
                        
                        }}>{d.texto}</Col>
                </Row>
            </Container>
            )
}

export default Barra;