
import React, { useState }  from 'react';

import { authIsAuthenticated, authLogin } from './../auth';
import {Redirect, Link} from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {cores} from './../config';

import './pagesStyle.css';


const Login = () => {

    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [statePass, setStatePass] = useState(true);
    const [processLogin, setProcessLogin] = useState(false);
    const [error, setError] = useState('');
    const mudaEstado = () => setStatePass(!statePass);

    const validaLogin = async () => {
        setError('');
        if((usuario.length<4) || (senha.length<3) ) {
            setError('Revise os dados e tenten novamente'); 
        }else{
        
            setProcessLogin(true);
            const data = await authLogin([usuario, senha]); 
            setTimeout(()=>{
                if(data.error) setError(data.error);
                setProcessLogin(false);
            }, 1000);   
        }     
    }

    const [logado, setLogado] = useState(authIsAuthenticated);

   


   return (logado) ? <Redirect to="/lances"/>  : (
        <Row style={{marginTop:'5vh'}} className="areaLogin">
            <Col xs={1} sm={3}></Col>
            <Col xs={10} sm={6}>
                <Row>
                    <Col md={12}>
                        <h3>Acesso restrito</h3>
                    </Col>
                    <Col md={12} style={{marginTop:'2vh'}}>
                        <TextField 
                            onChange={e => setUsuario(e.target.value)}
                            value={usuario} 
                            label="e-Mail"
                            style={{width:"100%"}}
                            variant="outlined"
                        />
                    </Col>
                    <Col md={12} style={{marginTop:'2vh'}}>
                        <TextField 
                            onChange={e => setSenha(e.target.value)}
                            value={senha} 
                            label="Senha"
                            type={(statePass) ? 'password' : 'text'}
                            style={{width:"88%"}}
                            variant="outlined"
                        />
                       
                        {(statePass) ? 
                            <VisibilityIcon style={{marginTop:'2.5vh', marginLeft:'3%', color:cores.primary}} onClick={ () => mudaEstado() }/> 
                            : 
                            <VisibilityOffIcon style={{marginTop:'2.5vh', marginLeft:'3%', color:cores.secondary}} onClick={ () => mudaEstado() }/>
                        }
                        
                       
                    </Col>
                    { (processLogin) ? <Col md={12} style={{marginTop:'2vh'}}>Validando login</Col> : '' }
                    { (error) ? <Col md={12} style={{marginTop:'2vh', color:'red'}}>{error}</Col> : '' }
                    
                    <Col md="3" style={{marginTop:'2vh'}}>
                    <div className="d-grid gap-2">
                        <Button className="btnToLogin" onClick={ ()=>validaLogin() } variant="primary" size="lg">
                            Login
                        </Button>
                    </div>
                    </Col>
                    <Col md="9" style={{marginTop:'2vh'}}>
                        Esqueceu sua senha? <div><Link to="/recuperar-senha">Clique aqui!</Link></div>
                    </Col>
                </Row>
            </Col>
            
            
        </Row>
            
   )
}

export default Login;