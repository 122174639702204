



const dark = false;
export const SOCKET_URL = 'https://www.sokt.com.br';
export const storage_prename = 'arcadenoe';

export const cores = (dark) ? {

    primary: 'black',
    secondary: 'blue',
    tertiary: '#9b9a9a',
    light: 'white',
    dark: 'black'

} : {

    primary: 'rgb(211,116,39)',
    secondary: '#80410e',
    tertiary: '#9b9a9a',
    light: 'white',
    dark: 'black'

    
}