
import axios from 'axios';
import {storage_prename} from './../config';

const ax = axios.create({
    crossDomain:true,
    //baseURL: "http://localhost/novaarca/api/"
    baseURL: "https://arcadenoeunai.com.br/central/api/"
});


export const parceiros = async () => {
    try {
        const resp = await ax.post('parceiros/');
        return resp.data;
    } catch(err){
        console.log(err);
    } 
}

export const lances = async (t) => {
    try {
        const resp = await ax.post('lances/'+t);
        return resp.data;
    } catch(err){
        console.log(err);
    } 
}

export const meuslances = async () => {
    try {
        const comprador = localStorage.getItem('@'+storage_prename+'/id');
        //const resp = await ax.post('meuslances/4116');
        const resp = await ax.post('meuslances/'+comprador);
        return resp.data;
    } catch(err){
        console.log(err);
    } 
}

export const darlance = async ([lote, valor]) => {
    
    try {
        const comprador = localStorage.getItem('@'+storage_prename+'/id');
        const resp = await ax.post('darlance/'+lote+'/'+valor+'/'+comprador);
        return resp.data;
    } catch(err){
        console.log(err);
    } 
}



export const get_buscar = async (palavra) => {
    var dados = [];
      await ax.get('buscar/'+palavra)
        .then(resp => {  
            dados = resp.data
        })
        .catch(error => {          
            console.log(error)       
        })
    return dados;
}

export const grupos = async () => {
    var dados = [];
      await ax.get('grupos/')
        .then(resp => {  
            dados = resp.data
           
        })
        .catch(error => {          
            console.log(error)       
        })
    return dados;
}

export const lotes = async () => {
    var dados = [];
      await ax.get('lotes/')
        .then(resp => {  
            dados = resp.data
        })
        .catch(error => {          
            console.log(error)       
        })
    return dados;
}

export const get_lote = async (numero) => {
    var dados = [];
    await ax.get('lote/'+numero)
        .then(resp => {  
            dados = resp.data
        })
        .catch(error => {          
            console.log(error)       
        })
    return dados;
}