import React, {useEffect, useState} from 'react';

import {Row, Col} from 'react-bootstrap';
import {Button, TextField} from '@material-ui/core';
import {authIsAuthenticated} from './../../auth';
import { makeStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';

import {cores} from './../../config';


const useStyles = makeStyles({
    root: {
      background: 'linear-gradient(0deg, rgb(211,116,39) 30%, rgba(211,116,39,.9) 90%)',
      border: 0,
      borderRadius: 3,
      boxShadow: '0 3px 5px 2px rgba(211,116,39, .3)',
      color: 'white',
      height: 48,
      padding: '0 30px',
      fontSize: '1.5vh'
    },
  });


function Darlance(props){
    
    
    const classes = useStyles();
    const [logado, setLogado] = useState(authIsAuthenticated);
    const [valor, setValor] = useState(0);

    const setLance = () =>{
        props.darUmLance(valor);
    }

    useEffect( ()=>{
        setValor(props.valor.toFixed(2));
    }, [props.valor])

    if(props.lotevendido=='S'){
        return(<Row>
                    <Col md={12} style={{alignItems:'center'}}>
                        <div style={{
                            width:'100%',
                            backgroundColor:'red', 
                            color:cores.light,
                            padding:'2vh',
                            borderRadius:'0.3vh',
                            fontSize:'1.5vh',
                            fontWeight: '400',
                            marginTop:'2vh',
                            marginBottom: '2vh',
                            textAlign:'justify'
                            }}>
                            
                            
                            Lote Vendido!
                            
                            
                                
                        </div>
                    </Col>
                
                </Row>)
    }else{
        if(!props.lanceLiberado){
            return (
                <Row>
                    <Col md={12} style={{alignItems:'center'}}>
                        <div style={{
                            width:'100%',
                            backgroundColor:'red', 
                            color:cores.light,
                            padding:'2vh',
                            borderRadius:'0.3vh',
                            fontSize:'1.5vh',
                            fontWeight: '400',
                            marginTop:'2vh',
                            marginBottom: '2vh',
                            textAlign:'justify'
                            }}>
                            
                            
                            Lote ainda não liberado pra receber lances.
                            
                            
                                
                        </div>
                    </Col>
                
                </Row>
            )
        }else{
            return (logado) ? (

                <Row style={{marginTop:'4vh'}}>
                    <Col xs={6}>           
                        <TextField  
                            onChange={e => setValor(e.target.value)}
                            value={valor}
                            fullWidth size="small" id="outlined-basic" label="R$" variant="outlined" />
                    </Col>
                    <Col xs={6}>
                        <Button
                            className={classes.root}
                            fullWidth
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick = {()=> setLance() }
                            value={valor}
                        >
                            Fazer um Lance
                        </Button>

                        
                    </Col>
                </Row>
            ) : (
                <>
                <Row>
                    <Col md={12} style={{alignItems:'center'}}>
                        <div style={{
                            width:'100%',
                            backgroundColor:cores.primary, 
                            color:cores.light,
                            padding:'2vh',
                            borderRadius:'0.3vh',
                            fontSize:'1.5vh',
                            fontWeight: '400',
                            marginTop:'2vh',
                            marginBottom: '2vh',
                            textAlign:'justify'
                            }}>
                            
                            
                            Para efetuar um lance, voce precisa estar logado.
                            
                            
                                
                        </div>
                    </Col>
                
                </Row>
            
            </>
            )
        }
    }
}

export default Darlance;