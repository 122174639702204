
import React, {useState, useEffect} from 'react';
import {Row, Col} from 'react-bootstrap';
import { cores } from './../config';

import {get_buscar} from './../model/lotes';

import Card from './../component/card';

const Buscar = (props) => {

    const [carregado, setCarregado] = useState(false);
    const [fetch, setFetchBusca] = useState(null);

   
    useEffect( async () => {
        if(!carregado){
            await get_buscar(props.valor).then( 
                data=>{
                    setFetchBusca(data);
                    setCarregado(false);
                }
            );
        }
    },[props.valor]);

   return (
        <Row>
                {
                (fetch) ? 
                    fetch.map( (lote, i) => {
                       return <Col key={`lote_${i}`}  xl={3} xs={6}>
                                    <Card 
                                        lote={lote.lote}  
                                        itens={lote.itens}
                                        spec={1}
                                    />
                                </Col>
                    })
                    : 
                    <div>bunada foi encontrado</div> 
                }
        </Row>
    )
}

export default Buscar;