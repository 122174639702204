import React from 'react';
import {Row, Col} from 'react-bootstrap';
import { cores } from '../config';
import './regras.css';
const Regra = () => {
    return (
        <Row style={{marginTop:'5vh'}}>
           
            <Col sm={12} style={{fontSize:'1.5vh', backgroundColor:'#fff', padding:'5vh'}} className="regras">
                
                <h4 style={{color:cores.primary}}>REGRAS DA 13a EDIÇÃO/2021 DO LEILÃO ARCA DE NOÉ</h4>

                <div>1. Esta doação <b>não é dedutível para Imposto de Renda;</b></div>
                <div>2. Os <b>lances mínimos por lotes serão de R$ 10,00 (dez reais)</b>, à partir do último lance ou do valor mínimo estipulado em cada lote;</div>
                <div>3. Objetos usados/antiguidades não terão garantia. Serão entregues da forma que foram doados e estão disponíveis para <b>visitação à partir do dia 26/07/2021 na Central de Captação na Rua Djalma Torres, 380, bairro Centro,</b> ao lado do SABIN, em horário comercial;</div>
                <div>4. As fotos dos produtos que estão no site são fotos reais ou ilustrativas das prendas;</div>
                <div>5. O <b>prazo final</b> do leilão virtual no site www.arcadenoeunai.com.br será até às <b>20:59:59 (vinte horas e cinquenta e nove minutos e cinquenta e nove segundos) do dia 12 de agosto de 2021;</b></div>
                <div>6. <b>Todos os lotes que receberem lances nos últimos 10 minutos, antes do encerramento do leilão (20:59:59), terão seus prazos prorrogados em mais 3 (três) minutos. E a cada novo lance, este prazo será prorrogado novamente, por mais 3 (três) minutos;</b></div>
                <div>7. Os lances, bem como seus contra <b>lances serão informados por mensagem de SMS,</b> no número do telefone informado no cadastro. Ressalvados os casos de problemas de envio das mensagens pela operadora de telefonia contratada;</div>
                <div>8. Os <b>objetos arrematados serão entregues ao comprador após o pagamento/acerto</b> do(s) lote(s) e deverão ser retirados na Central de Captação na Rua Djalma Torres, 380, bairro Centro, ao lado do SABIN, <b>até o dia 14/08/2021,</b> em horário comercial;</div>
                <div>9. A comissão organizadora não realizará entrega de lote(s) arrematado(s), todos os lotes <b>deverão ser retirados na Central de Captação</b> na Rua Djalma Torres, 380, bairro Centro;</div>
                <div>10. Serão disponibilizados até o término do leilão virtual <b>(12 de agosto de 2021),</b> na aba <b>ofertas relampâgo,</b> na parte inicial do site, <b>lotes que terão prazo de término diferenciado, sendo informado no próprio lote;</b></div>
                <div>11. Serão anunciados no site www.arcadenoeunai.com.br, na aba <b>Parque de Exposições,</b> alguns lotes que <b>serão leiloados somente na parte presencial do leilão, ou seja, no dia 15/08/2021;</b></div>
                <div>12. <b>Formas de pagamento:</b></div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;À vista 5% (cinco por cento) de desconto no Cartão de Débito e em Dinheiro;</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cartão de Crédito em uma parcela sem desconto;</div>
                <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Parcelado em até 5 (cinco) parcelas no cartão de crédito sem desconto.</div>
            </Col>
        </Row>
    )
}

export default Regra;