
import axios from 'axios';

import {storage_prename} from './config';

const ax = axios.create({
    crossDomain:true,
    baseURL: "https://arcadenoeunai.com.br/central/api/"
});

export const gD = (name) => {
    return localStorage.getItem('@'+storage_prename+'/'+name);
}

export const authIsAuthenticated = () => {
    const id   = localStorage.getItem('@'+storage_prename+'/id');
    const nome = localStorage.getItem('@'+storage_prename+'/nome');
    const email= localStorage.getItem('@'+storage_prename+'/email');
    const auth = localStorage.getItem('@'+storage_prename+'/auth');
    const appcode = localStorage.getItem('@'+storage_prename+'/appcode');
    if( (id) && (nome) && (email) && (auth) && (appcode)){
        return true;
    }
}

export const criarConta = async ([nome, usuario, senha, repetirSenha, celular, cpf, cep, rua, numero, bairro, cidade, uf, complemento]) => {
    try {
        const resp = await ax.post('cadastrar/',  {nome, usuario, senha, repetirSenha, celular, cpf, cep, rua, numero, bairro, cidade, uf, complemento})
        if(resp.data.id!='@'){
            return resp.data;
        }else{
            return resp.data;
        }
    } catch(err){
        console.log(err);
    }
    
}

export const recuperarSenha = async ([user, phone]) => {
    try {
        const resp = await ax.post('recuperar/',  {user, phone})
        if(resp.data.id!='@'){
            return resp.data;
        }else{
            return resp.data;
        }
    } catch(err){
        console.log(err);
    }
    
}

export const authLogin = async ([user, pass]) => {
    try {
        const resp = await ax.post('auth/',  {user, pass})
        if(resp.data.id!='@'){
                localStorage.setItem('@'+storage_prename+'/id', resp.data.id);
                localStorage.setItem('@'+storage_prename+'/nome', resp.data.nome);
                localStorage.setItem('@'+storage_prename+'/shortname', resp.data.shortname);
                localStorage.setItem('@'+storage_prename+'/email', resp.data.email);
                localStorage.setItem('@'+storage_prename+'/auth', resp.data.auth);
                localStorage.setItem('@'+storage_prename+'/appcode', resp.data.appcode);
                window.location.reload();
        }else{
            return resp.data;
        }
    } catch(err){
        console.log(err);
    }
    /*
    ax.post('auth/',  {user, pass})
        .then(resp => {  
            if(resp.data){
                localStorage.setItem('@'+storage_prename+'/id', resp.data.id);
                localStorage.setItem('@'+storage_prename+'/nome', resp.data.nome);
                localStorage.setItem('@'+storage_prename+'/shortname', resp.data.shortname);
                localStorage.setItem('@'+storage_prename+'/email', resp.data.email);
                localStorage.setItem('@'+storage_prename+'/auth', resp.data.auth);
                localStorage.setItem('@'+storage_prename+'/appcode', resp.data.appcode);
                window.location.reload();
            }else{
                localStorage.setItem('@'+storage_prename+'/error', resp.error);
            }
        })
        .catch(error => {          
            console.log(error)       
        })   
        */
}

export const logout = () => {
    localStorage.removeItem('@'+storage_prename+'/id');
    localStorage.removeItem('@'+storage_prename+'/nome');
    localStorage.removeItem('@'+storage_prename+'/shortname');
    localStorage.removeItem('@'+storage_prename+'/email');
    localStorage.removeItem('@'+storage_prename+'/auth');
    localStorage.removeItem('@'+storage_prename+'/appcode');
    window.location.reload();
}



export const darlance = () => {}

export const lances = () => {} 
