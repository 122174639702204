
import React, {useState, useEffect} from 'react';
import {Row, Col} from 'react-bootstrap';
import { cores } from './../config';
import {meuslances} from './../model/lotes';

import Divisor from './../component/divisor';
import Meulance from './../component/meulance';

const Lances = () => {

    const [carregado, setCarregado] = useState(false);
    const [fetchLances, setFetchLances] = useState(null);

    function getLances(){
        meuslances().then( data => setFetchLances(data));
    }

    useEffect(() => {
        if(!carregado){
            getLances();
            setCarregado(true);
        }
    }, [carregado]);

   return (
       <>
       
        <Row>

            <Divisor bg={cores.primary} titulo="Meus Lances" />

            <Col sm={12}>
                {(fetchLances) ? 
                   fetchLances.map((lance, i)=> <Meulance lance={lance} key={i}/> )
                : 
                <div>Sem lances até o momento</div> }
            </Col>
            
        </Row>
        
       
        </>
    )
}

export default Lances;