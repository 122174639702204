import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';


function Divisor(props){

    var size, color, bg;

    size = (props.full) ? 12 : 12;
    color= (props.cor) ? props.cor : 'white';
    bg   = (props.bg) ? props.bg : 'black';
    

    return (
        <Container style={{marginTop:'4vh'}}>
            <Row>
                <Col xs={size} style={{
                    backgroundColor:bg, 
                    color:color,
                    fontSize:'3vh', 
                    paddingTop:'1vh',
                    paddingBottom:'0.5vh',
                    borderRadius:'0.3vh'
                    }}>{props.titulo}</Col>
            </Row>
        </Container>
    )
}

export default Divisor; 