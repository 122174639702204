
import React, { useEffect, useState, useContext, useRef } from 'react';
import { SocketContext } from '../context/socket';

import {Row, Col} from 'react-bootstrap';

import Divisor from './../component/divisor';
import Card from './../component/card';

import {grupos, lotes} from './../model/lotes.js';
import { cores } from './../config';



const Home = () => {
    var isScrolling;

    const socket = useContext(SocketContext);
    const [carregado, setCarregado] = useState(false);
    const [fetchGrupo, setFetchGrupo] = useState(null);
    const [fetchLote, setFetchLote] = useState(null);
    const [listemScroll, setListemScroll] = useState(false);

    const onScroll = (e) => {
       
        window.clearTimeout( isScrolling );
        isScrolling = setTimeout(function() {
            if( (parseInt(window.pageYOffset)>10) && (listemScroll) ){
                localStorage.setItem('@arcascroll', window.pageYOffset);
            }
        }, 66);
    }

    useEffect(() => {
        window.addEventListener('scroll', onScroll);
        setListemScroll(true);
        return function cleanup() {
            setListemScroll(false);
            window.removeEventListener('scroll', onScroll);
        }
    });


    async function getGrupos(){
        await grupos().then( data => setFetchGrupo(data));
    }

    async function getLotes(){
        await lotes().then( data=> setFetchLote(data));
        var last = localStorage.getItem('@arcascroll');
        if(parseInt(last)) window.scroll(0, parseInt(last));
    }


    useEffect(() => {
        socket.emit('openpage', 'home');
        socket.on('novolance', (d)=>{})
        if(!carregado){
            socket.emit('join', {nome:'cefas', pagina:'home'});
            getGrupos();
            getLotes();
            setCarregado(true);
        }
       
    }, [carregado]);

   return (
       <>
          {renderGrupos(fetchGrupo, fetchLote)}
       </>
    )
}
//<Divisor key={item.id} titulo={item.nome}/>
function renderGrupos(grupos, lotes){
    if(grupos && lotes){
        const listGrupo = grupos.map((item, index) => {
            return (<div key={`divisor_${index}`}>
                    <Row>
                        <Col>
                            <Divisor color={(item.cor) ? item.cor : '#fff'} bg={(item.bg) ? item.bg : cores.primary} titulo={item.nome} />
                        </Col>
                    </Row>
                    <Row>
                    {
                    lotes.map((lote, lote_i) =>{ 
                        if(lote.lote.grupo===item.nome){
                        
                        return <Col key={`lote_${lote_i}`}  xl={3} xs={6}>
                                    <Card 
                                        lote={lote.lote}  
                                        itens={lote.itens}
                                        spec={1}
                                    />
                                </Col>
                        }
                    })
                    }
                    </Row>
                    </div>
                   )
        });
        return listGrupo;
    }
}


export default Home;