import React, { useState, useEffect } from 'react';
import {authIsAuthenticated} from './../auth';
import Logado from './menulogin/logado';
import { Link } from 'react-router-dom';
import {Row, Col } from 'react-bootstrap';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import LockIcon from '@material-ui/icons/Lock';
import './btnlogin.css';
function Btnlogin(){

    const [isLogado, setLogado] = useState(authIsAuthenticated());
    
    useEffect(() => {   
        setLogado(authIsAuthenticated());
    }, [isLogado]);

    return (isLogado) ?  
        <Logado/> 
    : <>
        <Row>
            <Col xs={6} sm={12}>
                <div className="areaBtnLogin">
                    <Link className="btnLogin" to="/cadastro">
                        <GroupAddIcon/><span>Criar conta</span>
                    </Link>
                </div>
            </Col>
            <Col xs={6} sm={12}>
            <div className="areaBtnLogin">
                <Link className="btnLogin" to="/login">
                    <LockIcon/><span>Fazer Login</span>
                </Link>
                </div>
            </Col>
        </Row> 
          
    </>
}

export default Btnlogin;
