
import React from 'react';
import {Redirect, Link} from 'react-router-dom';
import { Row, Col,  } from 'react-bootstrap';
import{cores} from './../../config';
import './../../page/pagesStyle.css';

import Contador from './../../component/contador';

const Meulance = (props) =>{

   

    return (
        <Row className="areaLogin" style={{
            marginTop:'5vh', 
            borderRadius:'0.5vh', 
            borderColor:cores.primary, 
            borderStyle:'solid',
            borderWidth:'1px',
            padding:'1vh'
        }}>
        <Col sm={2}>
            <Link to={`/${props.lance.numero}`}>
                <div style={{
            backgroundImage: `url(https://img.arcadenoeunai.com.br/2021/lotes/${props.lance.numero}.png?1)`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',         
            backgroundSize: 'contain',
            width: '100%',
            paddingTop:'20vh'
            }}>
            
            </div>
            </Link>
        </Col>
        <Col sm={7}>
            <Row>
                <Col sm={12}>
                    <h3 style={{color:cores.primary}}>
                    <Link to={`/${props.lance.numero}`}>LOTE {props.lance.numero}</Link>
                    </h3>
                </Col>
                <Col sm={12}>
                    {props.lance.doacao.itens.map( (it, i) => 
                        <div key={i}>{`${it.qt} ${it.abreviacao} ${it.produto}`}</div> 
                    )}
                </Col>
            </Row>
            
        </Col>
        <Col sm={3}>
            <Row>
                <Col sm={12}>
                    Meu Lance 
                    <h3>R$ {props.lance.valor}</h3>
                </Col>
                
                <Col sm={12}>
                    Valor Atual
                    <h3>R$ {props.lance.maior_lance}</h3>
                </Col>

                <Col sm={12}>
                    { (props.lance.vendido=='S') ? 
                        (props.lance.valor===props.lance.maior_lance) ? 
                            <div style={{
                                color:'#fff', 
                                backgroundColor: cores.primary,
                                padding: '1vh 2vh 1vh 2vh',
                                borderRadius:'1vh'

                            }}>Parabéns! Voce foi o vencedor deste lote.</div>
                        :
                        <div style={{
                            color:'#fff', 
                            backgroundColor: 'red',
                            padding: '1vh 2vh 1vh 2vh',
                            borderRadius:'1vh'

                        }}>Lances Finalizado.</div>
                    : 
                        <Contador startDate={props.lance.doacao.lote.strfim}/>
                    }
                </Col>

                
            </Row>
        </Col>
       
        
               
                   
               
       
        
    </Row>
    )
}

export default Meulance;