import React, {useState, useEffect} from 'react';
import Barra from './barra';
import Menuopc from './menu';
import {Container, Col, Row, Button} from 'react-bootstrap';
import Btnlogin from '../btnlogin';

import { cores } from './../../config';

import {useHistory, Link} from 'react-router-dom';
import SearchIcon from '@material-ui/icons/Search';
//import { TextField } from '@material-ui/core';
import {Form} from 'react-bootstrap';

function Header(d){

    const [busca, setBusca] = useState(null);
    let history = useHistory();

    const buscar = () => {
        history.push(`/buscar=${busca}`);
    }

   

    return (
        <>
        <Barra cor={cores.light} bg={cores.secondary} texto={d.barraTexto}/>
            
            
        <Container fluid className="header" style={{backgroundColor: cores.primary}}>
            <Container>
            <Row >
                <Col xs={12} sm={5} className="areaLogo" >
                    <Link to="/">
                    <img alt="1" className="eusou" src="img/eusou.png"/>
                    <img alt="2" className="arca" src="img/arca.png"/>
                    <img alt="3" className="rotary" src="img/rotary.png"/>
                    </Link>
                </Col>
                <Col xs={9} sm={4}>
                    <Row className="areaBuscar"> 
                        <Col xs={9}>
                            <Form.Control 
                                value={busca}  
                                onChange={e => setBusca(e.target.value)} 
                                type="text" 
                                placeholder="Buscar..."
                                onKeyPress={ 
                                    (e) => (e.charCode==13) ? buscar() : ''
                                }
                            />
                        </Col>
                        <Col xs={3} style={{margin:0, padding:0,textAlign:'right'}}>
                            <Button
                                className="btnSearch"
                                onClick={ ()=> buscar()}
                            >
                                <SearchIcon/>
                            </Button>
                        </Col>
                    </Row>
                    
                </Col>
                <Col xs={3} sm={3} className="areaName">
                    <Btnlogin />
                </Col>
            </Row>
            </Container>
        </Container>
        <Menuopc/>  
        </>
    )
}

export default Header;

