
import React, { useState }  from 'react';

import { recuperarSenha } from './../auth';
import {Redirect, Link} from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {cores} from './../config';

import './pagesStyle.css';


const Recuperar = () => {

    const [usuario, setUsuario] = useState('');
    const [celular, setCelular] = useState('');
    const [statePass, setStatePass] = useState(false);
    const [processLogin, setProcessLogin] = useState(false);
    const [error, setError] = useState('');
    const mudaEstado = () => setStatePass(!statePass);

    const validaLogin = async () => {
        //recuperarSenha
        setError('');
        if((usuario.length<4) || (celular.length<10) ) {
            setError('Revise os dados e tenten novamente'); 
        }else{
        
            setProcessLogin(true);
            const data = await recuperarSenha([usuario, celular]); 
            setTimeout(()=>{
                if(data.error) setError(data.error);
                setProcessLogin(false);
            }, 1000);   
        }     
    }

    

   


   
      return  <Row style={{marginTop:'5vh'}} className="areaLogin">
            <Col xs={1} sm={3}></Col>
            <Col xs={10} sm={6}>
                <Row>
                    <Col md={12}>
                        <h3>Recuperar senha</h3>
                    </Col>
                    <Col md={12} style={{marginTop:'2vh'}}>
                        <TextField 
                            onChange={e => setUsuario(e.target.value)}
                            value={usuario} 
                            label="e-Mail"
                            style={{width:"100%"}}
                            variant="outlined"
                        />
                    </Col>
                    <Col md={12} style={{marginTop:'2vh'}}>
                        <TextField 
                            onChange={e => setCelular(e.target.value)}
                            value={celular} 
                            label="Celular"
                            type={(statePass) ? 'password' : 'text'}
                            style={{width:"100%"}}
                            variant="outlined"
                        />
                       
                        
                        
                       
                    </Col>
                    { (processLogin) ? <Col md={12} style={{marginTop:'2vh'}}>Validando dados</Col> : '' }
                    { (error) ? <Col md={12} style={{marginTop:'2vh', color:'red'}}>{error}</Col> : '' }
                    
                    <Col md="6" style={{marginTop:'2vh'}}>
                    <div className="d-grid gap-2">
                        <Button className="btnToLogin" onClick={ ()=>validaLogin() } variant="primary" size="lg">
                            Recuperar Senha
                        </Button>
                    </div>
                    </Col>
                    <Col md="6" style={{marginTop:'2vh'}}>
                        Voltar para login <div><Link to="/login">Clique aqui!</Link></div>
                    </Col>
                </Row>
            </Col>
            
            
        </Row>
            
   
}

export default Recuperar;