import React, { Component } from "react";
import './timer.css';
import {Row, Col} from 'react-bootstrap';

import {realTime} from './../../tempo';

import { cores } from './../../config';

class Timer extends Component {
  constructor(props) {
    super(props);
    this.countDownId = null;
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      expired: false
    };
  }

  componentDidMount() {
    this.countDownId = setInterval(this.timerInit, 1000);
  }

  componentWillUnmount() {
    if (this.countDownId) {
      clearInterval(this.countDownId);
    }
  }

  timerInit = () => {
    const { startDate } = this.props;
    const now = new Date().getTime();
    
    const distance = startDate - now;
   
    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    // For countdown is finished
    if (distance < 0) {
      clearInterval(this.countDownId);
      this.setState({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
        expired: true
      });
      return;
    }
    this.setState({ days, hours, minutes, seconds, expired: false });
  };

  

  render() {

    const { days, hours, minutes, seconds, expired } = this.state;
    if (expired) {
      return (
        <Row style={{textAlign:'center'}} className="justify-content-md-center">
            <Col>
            
            </Col>
        </Row>
      )
    }
    return (
        
        <Row>
          <Col>
            <div className="areaClock bordas" style={{color:cores.primary, borderColor:cores.primary}}>
              <div style={{borderRightColor:cores.primary}} className="numero divis">{days}</div>
              <div style={{borderRightColor:cores.primary}} className="numero divis">{hours}</div>
              <div style={{borderRightColor:cores.primary}} className="numero divis">{minutes}</div>
              <div className="numero">{seconds}</div>              
            </div>
            <div className="areaClock bordasbtn" style={{color:cores.light, backgroundColor:cores.primary}}>
              <div className="texto">dias</div>
              <div className="texto">horas</div>
              <div className="texto">min.</div>
              <div className="texto">seg.</div>
            </div>
          </Col>          
        </Row>
        
     
    )
  }
}
export default Timer;


