import React from 'react';
import {Row, Col} from 'react-bootstrap';

import {cores} from './../../config';

const ItemLeft = (d) =>{
    return (
        <>
        <Row className='d-sm-block d-md-none'>
            <Col xl={12} >
                <div style={{
                    backgroundColor: '#f5f5f5',
                    backgroundImage: `url(https://img.arcadenoeunai.com.br/2022/doacao/${d.item.ficha}.png)`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',         
                    backgroundSize: 'contain',
                    borderRadius:'0.3vh',
                    maxWidth: '100vw',
                    maxHeight: '80vh',
                    paddingTop:'20vh'
                }}>                    
                </div>
            </Col>
            <Col xl={12}>
                <Row style={{
                    fontSize:'1.3vh', 
                    borderBottomWidth:'1px', 
                    borderBottomColor: cores.primary,
                    borderBottomStyle:'dotted',
                    paddingTop: '2vh',
                    marginBottom:'2vh',
                    paddingBottom: '2vh'
                    }}>
                   <Col md={12}><b>Item:</b> {parseInt(d.item.qt)} {d.item.abreviacao.toUpperCase()}  {d.item.produto}</Col>
                    {(d.item.dados_produto) ? <Col md={12}><b>Descrição:</b> {d.item.dados_produto.toUpperCase()}</Col> : ''}
                    <Col md={12}><b>Doador(a):</b> {d.item.doador}</Col>
                    <Col md={12}><b>Ficha:</b> #{d.item.ficha}</Col>
                </Row>
            </Col>
        </Row>
        <Row style={{marginBottom:'2vh', borderWidth:'1px', borderStyle:'solid', borderColor:'#ccc', borderRadius:'0.5vh'}}>
            <Col className='d-xl-block d-none' xl={3} style={{backgroundColor: '#f5f5f5'}}>                            
             
                    <div style={{
                        
                        backgroundImage: `url(https://img.arcadenoeunai.com.br/2022/doacao/${d.item.ficha}.png)`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',         
                        backgroundSize: 'contain',
                        maxWidth: '100%',
                        maxHeight: '20vh',
                        paddingTop:'20vh',
                        filter:'blur(0px)'
                    }}>                    
                    </div>               
                
            </Col>
            <Col className='d-xl-block d-none'  xl={1} style={{borderLeftWidth:'1px', borderLeftStyle:'solid', borderLeftColor:'#ccc', backgroundColor: '#f5f5f5'}}>

            </Col>
            <Col className='d-xl-block d-none'  xl={8} style={{backgroundColor: '#f5f5f5'}}>
               <Row style={{paddingTop:'3vh'}}>
                    <Col md={12}><b>Item:</b> {parseInt(d.item.qt)} {d.item.abreviacao.toUpperCase()}  {d.item.produto}</Col>
                    {(d.item.dados_produto) ? <Col md={12}><b>Descrição:</b> {d.item.dados_produto.toUpperCase()}</Col> : ''}
                    <Col md={12}><b>Doador(a):</b> {d.item.doador}</Col>
                    <Col md={12}><b>Ficha:</b> #{d.item.ficha}</Col>
               </Row>
            </Col>  
        </Row>
        </>
            
    )
}


const Doador = (props) => {
   
    return (props.itens) ? 
        <Row style={{marginTop:'2vh'}}>
            <Col>
        {
            props.itens.map( (it, s) => <ItemLeft key={`doador_${s}`} item={it} index={s}/>        )
        } 
        
            </Col>
        </Row>
    : 
        <div>sem intes no lote</div>
}

export default Doador;