import React from 'react';
import './carousel.css';
import {Carousel} from 'react-bootstrap';
import {cores} from './../../config';

function Slide(props){
 
    return ( <>
    <Carousel fade variant="dark" >
      <Carousel.Item className="carousel_item" style={{backgroundColor:'#f5f5f5'}}>
        <div style={{
          backgroundImage: `url(https://img.arcadenoeunai.com.br/2022/lotes/${props.lote.numero}.png?${Math.floor(Math.random() * 100)})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',         
          backgroundSize: 'contain'
        }}>
          
        </div>
         
      </Carousel.Item>
      {
          
          props.itens.map( (item, i)=>{
            return (
            <Carousel.Item key={`slide_${i}`} className="carousel_item" style={{backgroundColor:'#f5f5f5'}}>

        <div style={{
          backgroundImage: `url(https://img.arcadenoeunai.com.br/2022/doacao/${item.ficha}.png?${Math.floor(Math.random() * 100)})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',         
          backgroundSize: 'contain'
        }}>
          
          <p>
            Doador: {item.doador}
          </p>
        </div>
            
            
            </Carousel.Item>
            )
          })
        
      }
    </Carousel>
  </>
    )
}

export default Slide;