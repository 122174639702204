import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {cores} from './../config';
import {Row, Col} from 'react-bootstrap';

const Olhar = (props) => {
    
    const short = (props.short) ? true : false;

    if (props.spec > 1){ 
    return (short)?
       <VisibilityIcon style={{fontSize:'6vh', color:cores.primary}} />
    :
    <Row style={{paddingTop:'2vh'}} >
        <Col>
            <Row style={{padding:'1vh'}} >
            
                <Col md={2} style={{textAlign:'center'}}>
                    <VisibilityIcon style={{fontSize:'6vh', color:cores.primary}} /> 
                </Col>
                <Col>
                {props.spec} pessoas olhando este lote neste momento!
                </Col>

            </Row>
        </Col>
    </Row>
    }else{ return <div></div> };

}

export default Olhar;