import React from 'react';



import {Container, Col, Row} from 'react-bootstrap'

function Footer(){

    return (
        <>
            <Container fluid className="footer">
                <Container>
                <Row>
                    <Col xs={12} sm={4} style={{paddingTop:'2vh'}}>
                        <div style={{color:'rgb(211,116,39)', fontWeight:'bold'}}>Central Arca de Noé</div>
                        <div>Rua Djalma Torres, 380</div>
                        <div>Centro - Unaí, MG</div>
                    </Col>
                    <Col xs={12} sm={4}>
                        <img alt="1"  src="img/rotarys.jpeg"/>
                    </Col>
                    <Col xs={12} sm={4}>
                        <img alt="1" src="img/apoios.jpeg"/>
                    </Col>
                </Row>
                </Container>
            </Container>
        </>
    )
}

export default Footer;

