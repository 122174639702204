import React from 'react';

import {Route, Switch, Redirect, useParams } from 'react-router-dom';

import Home from './page/Home';
import Lances from './page/Lances';
import Lote from './page/Lote';
import Login from './page/Login';
import Cadastro from './page/Cadastro';
import Recuperar from './page/Recupera';
import Buscar from './page/Buscar';
import Regra from './page/Regra';

import { authIsAuthenticated } from './auth';



const PrivateRoute = ({ component: Component, ...rest}) => (
    
    <Route 
        {...rest}
        render={props =>
            authIsAuthenticated() ? (
                <Component {...props}/>
            ) : (
                <Redirect to={{ pathname:"/login", state:{ from: props.location } }} />
            )
        }
    />
)



const Routes = () => (

        <Switch>
            
            <Route exact path="/" component={ () => <Home />}/>
            <Route path="/login" component={ () => <Login/> }/>
            <Route path="/cadastro" component={ ()=> <Cadastro/> }/>
            <Route path="/recuperar-senha" component={ ()=> <Recuperar/> }/>
            <Route path="/regra" component={ ()=> <Regra/> }/>
    
            <PrivateRoute path="/lances" component={ ()=> <Lances/> }/>
            <PrivateRoute path="/pagamentos" component={ ()=> <h1>Pagamentos</h1>}/>
            <Route path="/buscar=:valor" children={ <ComponentBusca/> }/>
            <Route path="/:id" children={ <Child/> }/>
           
            
        </Switch>
    
)

function ComponentBusca(){
    let {valor} = useParams();
    return <Buscar valor={valor}/>
}


function Child(){
    let {id} = useParams();
    return <Lote numero={id} home={false}/>
}

export default Routes;