import './app.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, {useState, useEffect} from 'react';


import Routes from './routes';
import { BrowserRouter } from 'react-router-dom';
import { Row,  Container } from 'react-bootstrap';


import Header from './component/layout/header';
import Footer from './component/layout/footer';

import Parceiro from './component/parceiro';
import {parceiros} from './model/lotes';
import { cores } from './config';

const App = () => {
  
  const [fetchParceiro, setFetchParceiro] = useState([]);
  const [carregado, setCarregado] = useState(false);

  async function getParceiros(){
    await parceiros().then( data=>setFetchParceiro(data) );
  } 

  useEffect(() => {
    
    if(!carregado){
        getParceiros();
        setCarregado(true);
    }
   
}, [carregado]);
  
  return (
    
      <BrowserRouter>
      
      <div style={{background:'linear-gradient(#e6e6e6, #ffffff)'}}>
      <Header barraTexto="Dúvidas, sugestões ou reclamações (38) 9.9894-4760"/>
      <Container>

          <Routes/>
          <Row style={{marginTop:'4vh', paddingTop:'4vh', borderTopWidth:'2xp', borderTopStyle:'solid', borderTopColor:cores.primary}}>
            { (fetchParceiro) ? 
                fetchParceiro.map( (parc, p)=> <Parceiro key={p} parc={parc}/>) :
                <div></div>
            }
          </Row>
      </Container> 
      <Footer/>    
      </div>

    </BrowserRouter>
  
  )
}

export default App;
