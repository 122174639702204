import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ImageIcon from '@material-ui/icons/Image';
import WorkIcon from '@material-ui/icons/Work';
import BeachAccessIcon from '@material-ui/icons/BeachAccess';

import {cores} from './../../config';
import Divisor from './../divisor';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 600,
    borderWidth:'1px',
    borderColor:'#ccc',
    backgroundColor: theme.palette.background.paper,
  },
}));


function Ultlance(props){
    const classes = useStyles();
    return  (
    <>
      <Divisor bg={cores.primary} cor={cores.light} titulo="Últimos lances"/>
        <List className={classes.root} >
          {props.lances.map( (item, i) => {
            return( <ListItem divider key={i}> 
             
              <ListItemText  primary={item.nome} secondary={`${item.dia} ${item.hora}`} />
              <ListItemText  primary={`R$ ${parseFloat(item.valor).toFixed(2).replace('.', ',')}`}/>
            </ListItem>)
          }
          )}    
        </List>
  </>)

}

export default Ultlance;