import React, {useState} from 'react';

import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import {gD, logout} from './../../auth';
import { Link } from 'react-router-dom';
import './logado.css';

function Logado(){

    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    function caminho(local){
       <Link to={local}/>
       handleClose()
    }


    const shortname = gD('shortname');

    return  (
        <>
            <Button aria-controls="simple-menu" aria-haspopup="true" onClick={ handleClick}>
                <div style={{width:'100%'}}>
                    <AccountCircleIcon/>
                </div> 
                <div className="areaLogadoNome">Olá <b>{shortname}</b></div>
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem component={Link} to="/lances" onClick={handleClose}>Meus Lances</MenuItem>
                <MenuItem onClick={ logout }>Finalizar Acesso</MenuItem>
            </Menu>
        </>
    )
}

export default Logado;
