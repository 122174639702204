
import React, {useState} from 'react';
import { authIsAuthenticated, criarConta, gD } from './../auth';
import {useHistory, Redirect, Link} from 'react-router-dom';
import { Row, Col, Button } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import {cores} from './../config';

import './pagesStyle.css';
import { UnfoldLess } from '@material-ui/icons';


const Cadastro = () => {

    const [nome, setNome] = useState('');
    const [usuario, setUsuario] = useState('');
    const [senha, setSenha] = useState('');
    const [repetirSenha, setRepetirSenha] = useState('');
    const [celular, setCelular] = useState('');
    const [cpf, setCpf] = useState('');
    const [cep, setCep] = useState('');
    const [rua, setRua] = useState('');
    const [numero, setNumero] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUf] = useState('');
    const [complemento, setComplemento] = useState('');
    
    const [statePass, setStatePass] = useState(true);
    const [processLogin, setProcessLogin] = useState(false);
    const [error, setError] = useState('');
    const mudaEstado = () => setStatePass(!statePass);

    let history = useHistory();

    const validaLogin = async () => {
        setError('');
        if((usuario.length<4) || (senha.length<3) ) {
            setError('Revise os dados e tenten novamente'); 
        }else{
        
            setProcessLogin(true);
            const data = await criarConta([nome, usuario, senha, repetirSenha, celular, cpf, cep, rua, numero, bairro, cidade, uf, complemento]); 
            setTimeout(()=>{
                if(data.error){ setError(data.error); }
                if(data.id!='@'){ 
                    history.push('/login');
                }
                setProcessLogin(false);
            }, 1000);   
        }     
    }

    const [logado, setLogado] = useState(authIsAuthenticated);

   


   return (logado) ? <Redirect to="/lances"/>  : (
   
  
    <Row style={{marginTop:'5vh'}} className="areaLogin">
    <Col xs={1} sm={3}></Col>
    <Col xs={10} sm={6}>
        <Row>
            <Col md={12}>
                <h3>Criar conta</h3>
            </Col>
            <Col md={12} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setNome(e.target.value)}
                    value={nome} 
                    label="Nome completo"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>
            <Col md={12} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setUsuario(e.target.value)}
                    value={usuario} 
                    label="e-Mail"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>
            <Col md={6} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setSenha(e.target.value)}
                    value={senha} 
                    label="Senha"
                    type={(statePass) ? 'password' : 'text'}
                    style={{width:"80%"}}
                    variant="outlined"
                />
               
                {(statePass) ? 
                    <VisibilityIcon style={{marginTop:'2.5vh', marginLeft:'10%', color:cores.primary}} onClick={ () => mudaEstado() }/> 
                    : 
                    <VisibilityOffIcon style={{marginTop:'2.5vh', marginLeft:'10%', color:cores.secondary}} onClick={ () => mudaEstado() }/>
                }
                
            </Col>
            
            <Col xs={6} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setRepetirSenha(e.target.value)}
                    value={repetirSenha} 
                    label="Confirmar Senha"
                    type={(statePass) ? 'password' : 'text'}
                    style={{width:"100%"}}
                    variant="outlined"
                />
                
            </Col>

            <Col md={6} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setCelular(e.target.value)}
                    value={celular} 
                    label="Celular"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>

            <Col md={6} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setCpf(e.target.value)}
                    value={cpf} 
                    label="CPF"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>

            <Col md={4} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setCep(e.target.value)}
                    value={cep} 
                    label="CEP"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>
            <Col md={8} style={{marginTop:'2vh'}}></Col>
            <Col md={8} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setRua(e.target.value)}
                    value={rua} 
                    label="Rua"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>
            <Col md={4} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setNumero(e.target.value)}
                    value={numero} 
                    label="Número"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>
            <Col md={6} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setComplemento(e.target.value)}
                    value={complemento} 
                    label="Complemento"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>

            <Col md={6} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setBairro(e.target.value)}
                    value={bairro} 
                    label="Bairro"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>

            <Col md={8} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setCidade(e.target.value)}
                    value={cidade} 
                    label="Cidade"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>

            <Col md={4} style={{marginTop:'2vh'}}>
                <TextField 
                    onChange={e => setUf(e.target.value)}
                    value={uf} 
                    label="Estado"
                    style={{width:"100%"}}
                    variant="outlined"
                />
            </Col>

            <Col md={12} style={{marginTop:'2vh'}}>
               <div 
                style={{ 
                    height:'20vh', 
                    padding:'2.5vh', 
                    backgroundColor:'#fff', 
                    borderStyle:'solid', 
                    borderWidth:'1px', 
                    borderColor:'#ccc',
                    overflowY:'scroll',
                    fontSize:'1.4vh',
                    textAlign:'justify'
                    }}>
               
               <div style={{color:'#333', fontWeight:'bold', marginBottom:'3vh'}}>REGRAS DA 13a EDIÇÃO/2021 DO LEILÃO ARCA DE NOÉ</div>

<div>1. Esta doação <b>não é dedutível para Imposto de Renda;</b></div>
<div>2. Os <b>lances mínimos por lotes serão de R$ 10,00 (dez reais)</b>, à partir do último lance ou do valor mínimo estipulado em cada lote;</div>
<div>3. Objetos usados/antiguidades não terão garantia. Serão entregues da forma que foram doados e estão disponíveis para <b>visitação à partir do dia 26/07/2021 na Central de Captação na Rua Djalma Torres, 380, bairro Centro,</b> ao lado do SABIN, em horário comercial;</div>
<div>4. As fotos dos produtos que estão no site são fotos reais ou ilustrativas das prendas;</div>
<div>5. O <b>prazo final</b> do leilão virtual no site www.arcadenoeunai.com.br será até às <b>20:59:59 (vinte horas e cinquenta e nove minutos e cinquenta e nove segundos) do dia 12 de agosto de 2021;</b></div>
<div>6. <b>Todos os lotes que receberem lances nos últimos 10 minutos, antes do encerramento do leilão (20:59:59), terão seus prazos prorrogados em mais 3 (três) minutos. E a cada novo lance, este prazo será prorrogado novamente, por mais 3 (três) minutos;</b></div>
<div>7. Os lances, bem como seus contra <b>lances serão informados por mensagem de SMS,</b> no número do telefone informado no cadastro. Ressalvados os casos de problemas de envio das mensagens pela operadora de telefonia contratada;</div>
<div>8. Os <b>objetos arrematados serão entregues ao comprador após o pagamento/acerto</b> do(s) lote(s) e deverão ser retirados na Central de Captação na Rua Djalma Torres, 380, bairro Centro, ao lado do SABIN, <b>até o dia 14/08/2021,</b> em horário comercial;</div>
<div>9. A comissão organizadora não realizará entrega de lote(s) arrematado(s), todos os lotes <b>deverão ser retirados na Central de Captação</b> na Rua Djalma Torres, 380, bairro Centro;</div>
<div>10. Serão disponibilizados até o término do leilão virtual <b>(12 de agosto de 2021),</b> na aba <b>ofertas relampâgo,</b> na parte inicial do site, <b>lotes que terão prazo de término diferenciado, sendo informado no próprio lote;</b></div>
<div>11. Serão anunciados no site www.arcadenoeunai.com.br, na aba <b>Parque de Exposições,</b> alguns lotes que <b>serão leiloados somente na parte presencial do leilão, ou seja, no dia 15/08/2021;</b></div>
<div>12. <b>Formas de pagamento:</b></div>
<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;À vista 5% (cinco por cento) de desconto no Cartão de Débito e em Dinheiro;</div>
<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cartão de Crédito em uma parcela sem desconto;</div>
<div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Parcelado em até 5 (cinco) parcelas no cartão de crédito sem desconto.</div>
               </div>
            </Col>
            { (processLogin) ? <Col md={12} style={{marginTop:'2vh'}}>Validando cadastro</Col> : '' }
            { (error) ? <Col md={12} style={{marginTop:'2vh', color:'red'}}>{error}</Col> : '' }
            
            <Col md="5" style={{marginTop:'2vh'}}>
            <div className="d-grid gap-2">
                <Button className="btnToLogin" onClick={ ()=>validaLogin() } variant="primary" size="lg">
                    Criar conta
                </Button>
            </div>
            </Col>
            <Col md="7" style={{marginTop:'2vh'}}>
                Já tem cadastro? <div><Link to="/login">Clique aqui!</Link></div>
            </Col>
        </Row>
    </Col>
    
    
</Row>
    )
}

export default Cadastro;